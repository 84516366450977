import React from 'react'

const Contact = () => {
  return (
    <div className='flex flex-col gap-4 my-10 text-1xl font-bold justify-center items-center'>
      <h1>DualDealMart</h1>
      <p>contact:9791818045</p>
      <p>Email:secretsquare302@gmail.com</p>
      <p>East street,uppparpatti,Theni</p>
    </div>
    
  )
}

export default Contact